@include overrides();

@mixin theme-defaults($root) {
  // sass-lint:disable no-important
  .#{$root}-theme {
    color: $primary-color !important;
  }

  .#{$root}-theme-background-with-transparency {
    background: rgba($primary-color, .12) !important;
  }

  .#{$root}-theme-highlight {
    background-color: $primary-color !important;
    color: $white;
  }

  .#{$root}-theme-indicator {
    &:before,
    &:after {
      color: $primary-color !important;
    }
  }

  .#{$root}-theme-background-indicator,
  .#{$root}-theme-inherit-background-indicator *:not([class*=#{$root}-theme-]) {
    &:before,
    &:after {
      background: $primary-color !important;
    }
  }

  .#{$root}-theme-gradient {
    // duplicate required as fallback for IE
    // sass-lint:disable no-duplicate-properties
    background: $primary-color !important;
    background: transparent linear-gradient(to right bottom, rgba($primary-color, .7) 49%, rgba($primary-color, 1) 50%) !important;
    // sass-lint:enable no-duplicate-properties
    color: $white;
  }

  body {
    .#{$root}-theme-border,
    .#{$root}-theme-border:before,
    .#{$root}-theme-inherit-border *:not([class*=#{$root}-theme-]) {
      border-color: $primary-color !important;
    }
  }

  .#{$root}-theme-border-top,
  .#{$root}-theme-border-top:before {
    border-top-color: $primary-color !important;
  }

  .#{$root}-theme-border-right,
  .#{$root}-theme-border-right:before {
    border-right-color: $primary-color !important;
  }

  .#{$root}-theme-border-bottom,
  .#{$root}-theme-border-bottom:before {
    border-bottom-color: $primary-color !important;
  }

  .#{$root}-theme-border-left,
  .#{$root}-theme-border-left:before {
    border-left-color: $primary-color !important;
  }

  .#{$root}-theme-button {
    background-color: $primary-color !important;
    border: 2px solid $primary-color !important;
    color: $white;

    &:hover {
      background-color: $white !important;
      border-color: $aspen-silver !important;
      color: $primary-color !important;
    }
  }

  .#{$root}-theme-hover-border-color {

    &:hover {
      border-color: $primary-color !important;
    }
  }

  .#{$root}-theme-button-highlight {

    background-color: $white;
    border-color: $aspen-silver;

    &:hover {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
      color: $white;
    }
  }

  .#{$root}-theme-hover {

    &:hover {
      color: $primary-color !important;
    }

  }

  .#{$root}-theme-button-primary {
    background-color: $white;
    border-color: $primary-color !important;
    color: $primary-color !important;

    a {
      color: $primary-color;
    }

    &:hover {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
      color: $white;
    }
  }

  //sass-lint:disable no-qualifying-elements
  .#{$root}-theme-inherit-color-a a:not([class*=#{$root}-theme-]) {
    color: $primary-color !important;
  }
  //sass-lint:enable no-qualifying-elements
}

@mixin brand-palette() {
  .brand-hint-bg {
    background-color: $hint !important;
  }

  .brand-passion-bg {
    background-color: $brand-passion-color !important;
  }

  .brand-passion-text {
    color: $brand-passion-color !important;

    &-pseudo:before {
      color: $brand-passion-color !important;
    }
  }

  .brand-text {
    color: $brand-color !important;
  }

  .brand-border {
    border-color: $brand-color !important;
  }

  .brand-bg {
    &:before {
      background-color: $brand-color !important;
    }
  }

  .brand-hi {
    background-color: $highlight !important;
    color: $white;

    &-pseudo:before {
      background-color: $highlight !important;
    }
  }

  .brand-low {
    background-color: $lowlight !important;
    color: $white;
  }

  .brand-text-low {
    &:before {
      color: $lowlight !important;
    }
  }

  .with-topbox {
    .mod-header .secondary {
      background-color: $brand-passion-color !important;
      border-color: $brand-color;
      color: $white;

      a {
        color: $white;
      }
    }
  }
}

@include brand-palette();

@mixin scale($width, $height) {
  $scaled-width: $scale-ratio * $height;
  @if($width > $scaled-width) {
    height: #{$height};
    width: #{$scaled-width};
  }  @else {
    height: #{round($width/($scale-ratio))};
    width: #{$width};
  }
}

@include theme-defaults('section');

@each $section, $color in $sections {

  .channel-#{$section},
  .publication-theme .channel-#{$section} {

    .section-theme {
      color: $color $important;
    }

    .section-theme-highlight {
      background: $color $important;
      color: $white;
    }

    .section-theme-indicator {
      &:before,
      &:after {
        color: $color $important;
      }
    }

    .section-theme-background-indicator,
    .section-theme-inherit-background-indicator *:not([class*=section-theme-]) {
      &:before,
      &:after {
        background: $color $important;
      }
    }

    .section-theme-background-with-transparency {
      background: rgba($color, .12) $important;
    }

    // TODO: this needs reworking asap. Seldon teasers don't obey the rules of inheriting channel colors
    .seldon-recs {
      .section-theme {
        color: $primary-color $important;
      }
    }

    .section-theme-gradient {
      // duplicate required as fallback for IE
      // sass-lint:disable no-duplicate-properties
      background: $color $important;
      background: transparent linear-gradient(to right bottom, rgba($color, .7) 49%, rgba($color, 1) 50%) $important;
      // sass-lint:disable no-duplicate-properties
    }
    .section-theme-border,
    .section-theme-inherit-border *:not([class*=section-theme-]) {
      border-color: $color $important;
    }

    .section-theme-button {
      background-color: $color $important;
      border-color: $color $important;

      &:hover {
        background-color: $white $important;
        border-color: $aspen-silver $important;
        color: $color $important;
      }
    }
  }
}

@include theme-defaults('publication');

.publication-theme-icon {
  &:before {
    @if variable-exists(icon) {
      background: transparent url($icon) 0 0 no-repeat;
    }
    content: '';
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
  }
}

.publication-theme {
  @include theme-defaults('section');
}

.partner-carousel #logo {
  @include scale-by-height($logo-dimensions, 45);
}

.partner-footer #logo {
  @include scale-by-height($logo-dimensions, 35);
}

@include breakpoint-specific('device-tablet-portrait-upwards') {
  #logo {
    @include scale-by-height($logo-dimensions, 60);
  }

  .partner-footer #logo {
    @include scale-by-height($logo-dimensions, 50);
  }
}

@include breakpoint-specific('device-tablet-landscape-upwards') {
  #logo {
    @include scale-by-height($logo-dimensions, 70);
  }

  .partner-footer #logo {
    @include scale(250px, 70px);
  }
}

@include breakpoint-specific('device-desktop') {
  #logo {
    @include scale-by-height($logo-dimensions, 80);
  }

  .partner-footer #logo {
    @include scale(250px, 80px);
  }
}

// fiddly things that are just too hard to apply generic classes to

// sass-lint:disable no-qualifying-elements
.pagination ul li a {
  color: $primary-color;
}

.section-head.section-info {
  a,
  p:first-child a {
    color: $primary-color;
  }
}

.pagination ul li.active a,
.pagination ul li a:hover {
  background-color: $primary-color;
  color: $white;
}
// sass-lint:enable no-qualifying-elements

// sass-lint:disable no-important
.topic-more-on a {
  color: $primary-color $important;
}
// sass-lint:enable no-important

.primary-loader-icon {
  background-image: url($html-loading-logo);
}

.primary-theme-spinner {
  animation: spin 1.5s linear infinite;
  border: 5px solid;
  border-color: transparent $primary-color $primary-color;
  border-radius: 50%;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.primary.publication-theme-highlight.earthquake {
  background-color: var(--pub-earthquake-color) !important; // sass-lint:disable-line no-important
  color: $white;
}

.sticky-header {
  #logo {
    background-image: url($reversed-logo);
  }

  .primary.publication-theme-highlight.earthquake {
    background-color: $white !important; // sass-lint:disable-line no-important
    color: $white;

    #logo {
      background-color: var(--pub-earthquake-color) !important; // sass-lint:disable-line no-important
    }
  }
}

.sharebar {
  #logo {
    background-image: url($icon);
  }
}

.live-event {
  .live-event-entry  {
    .sharebar-comments {
      background-color: $brand-color $important;
      color: $white;
      &:hover {
        background-color: darken($brand-color, 5%) $important;
      }
    }
  }
}

@include breakpoint-specific('device-tablet-portrait-upwards') {
  .sharebar {
    #logo {
      @include scale-by-height($logo-dimensions, 60);
      background-image: url($reversed-logo);
    }
  }
}

.cta-bar-comment-button {
  background-color: $brand-color $important;
  color: $white;
  &:hover {
    background-color: darken($brand-color, 5%) $important;
  }
}

.cta-bar-svg {
  fill: $white;
}
