@font-face {
  font-display: auto;
  font-family: 'futura-pt-condensed';
  font-stretch: normal;
  font-style: normal;
  font-weight: 800;
  src: url('https://use.typekit.net/af/3b8138/00000000000000000001203f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3') format('woff2'),
  url('https://use.typekit.net/af/3b8138/00000000000000000001203f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3') format('woff'),
  url('https://use.typekit.net/af/3b8138/00000000000000000001203f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3') format('opentype');
}

@font-face {
  font-display: auto;
  font-family: 'futura-pt-condensed';
  font-stretch: normal;
  font-style: italic;
  font-weight: 800;
  src: url('https://use.typekit.net/af/6b4d7c/000000000000000000012040/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3') format('woff2'),
  url('https://use.typekit.net/af/6b4d7c/000000000000000000012040/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3') format('woff'),
  url('https://use.typekit.net/af/6b4d7c/000000000000000000012040/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3') format('opentype');
}
