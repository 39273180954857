// sass-lint:disable indentation
// sass-lint:disable no-misspelled-properties
@font-face {
  ascent-override: 106%;
  font-display: swap;
  font-family: 'Signika Negative-fallback';
  size-adjust: 95.54000000000005%;
  src: local('Arial');
}

@font-face {
  ascent-override: 105%;
  font-display: swap;
  font-family: 'Open Sans-fallback';
  size-adjust: 105.41%;
  src: local('Arial');
}
