// sass-lint:disable indentation

@font-face {
  font-family: 'Signika Negative Bold';
  font-style: normal;
  font-weight: 400;
  src: local('Signika Negative Bold'),
  url('/@trinitymirrordigital/chameleon-branding/webfonts/woff2/SignikaNegative-Bold.woff2') format('woff2'),
  url('/@trinitymirrordigital/chameleon-branding/webfonts/woff/SignikaNegative-Bold.woff') format('woff');
}

