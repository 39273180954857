// sass-lint:disable no-important

#signin {

  &:before {
    border-color: transparent transparent $header-additional;
  }

  .label {
    background: $header-additional;

    .icon {
      background-color: $header-additional;
    }
  }

  .bubble-wrapper {
    background: $header-additional;

    .bubble {
      color: $primary-color;
    }
  }
}

#sso-logout {
  color: $primary-color;
}

.gigya-progressive-custom,
.gigya-screen-dialog-mobile.gigya-progressive-custom {

  .gigya-screen-dialog-top {

    .gigya-screen-dialog-close {

      &:before {
        color: $primary-color;
      }
    }
  }

  .gigya-style-modern {
    .arrow {
      &:after {
        color: $primary-color;
      }
    }

    .top-label {
      background: $primary-color;
    }
  }

  .gigya-composite-control-submit {
    .gigya-input-submit {
      background: $primary-color;

      &:hover {
        color: $primary-color !important;
      }
    }
  }
}

.screen-default .gigya-screen {
  .avatar:after {
    background: $primary-color;
    @if variable-exists(icon) {
      background-image: url($icon);
    }
  }
}

.screen-premium .gigya-screen {
  .avatar {
    @if variable-exists(premium-logo) {
      background-image: url($premium-logo);
    }
  }
}

.gigya-screen {

  .benefits {
    @if variable-exists(screenset-bkgnd) {
      background-image: url($screenset-bkgnd);
    }
  }

  .benefits--logo {
    @if variable-exists(premium-logo) {
      background-image: url($premium-logo);
    }
  }

  .gigya-composite-control-submit .gigya-input-submit {
    background-color: $primary-color !important;

    &:hover {
      color: $primary-color;
    }
  }
}

.gigya-native-login-form .gigya-layout-row {

  h2 {
    @include title-font(36px);
  }

  .gigya-composite-control.gigya-composite-control-submit .gigya-input-submit {
    background-color: $primary-color !important;
  }
}

.gigya-reset-password-form {

  .reset-pswd {
    @include title-font(36px);
  }
}

#gigya-forgot-password-success-screen {
  .reset-pswd {
    @include title-font(36px);
  }

  .gigya-composite-control.gigya-composite-control-label a {
    background-color: $primary-color !important;

    &:hover {
      color: $primary-color !important;
    }
  }
}

.gigya-register-form {

  h2 {
    @include title-font(36px);
  }

}

.gigya-login-form-customized .gigya-native-login {

  .gigya-composite-control.gigya-composite-control-header {
    @include title-font(36px);
  }

  .gigya-input-submit {
    background: $primary-color !important;

    &:hover {
      color: $primary-color !important;
    }
  }
}

// sass-lint:disable no-qualifying-elements
div.gigya-screen-dialog-top-customized {
  &:before {
    color: $primary-color !important;
  }
}

.gigya-progressive-custom {

  .top-label {
    background: $primary-color !important;
  }

  &.gigya-style-modern .arrow:after,
  div.gigya-screen-dialog-close:before {
    color: $primary-color !important;
  }
}
// sass-lint:enable no-qualifying-elements

//scss-lint:disable SelectorDepth, UnnecessaryParentReference
[id$='_showShareUI_emailCanvas'] {

  & > tbody > tr {
    &:nth-child(5) > td {
      tr {
        background: $primary-color !important;
      }
    }
  }
}
//scss-lint:enable SelectorDepth, UnnecessaryParentReference

//styles for report a comment

//scss-lint:disable NestingDepth
.gig-comments-container {

  .gig-comments-dialog {
    .gig-comments-dialog-caption-container {
      .gig-comments-dialog-caption {
        @include title-font(36px);
      }

      .gig-comments-dialog-close {
        &:before {
          color: $primary-color;
        }
      }
    }

    .gig-comments-dialog-body {
      .gig-comments-dialog-textbox-button {
        background: $primary-color;
        border: 2px solid $primary-color;

        &:hover {
          color: $primary-color;
        }
      }
    }

    .gig-comments-dialog-buttons {
      .gig-comments-dialog-button {
        background: $primary-color;
        border: 2px solid $primary-color;

        &:hover {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }
}
//scss-lint:enable NestingDepth

//styles for share popup

// sass-lint:disable class-name-format
.gig-simpleShareUI {

  .gig-simpleShareUI-inner {
    border-left: 4px solid $primary-color;
  }

  .gig-simpleShareUI-closeButton {
    &:before {
      color: $primary-color;
    }
  }
}
// sass-lint:enable class-name-format

// styles for tag a commenter popup
.gig-comments-usertaggingbox {

  .gig-usertagging-title {
    border-top: 5px solid $primary-color;
  }
}

// competitions

.jotform-form {
  border: 1px solid $primary-color;

  .competition-header {
    border-bottom: 1px solid $primary-color;
  }

  .form-line {
    .dropdown {

      &:before {
        color: $primary-color;
      }
    }
  }

  .form-label-center {
    color: $primary-color;
  }

  .form-single-column.form-multiple-column {
    border-bottom: 1px solid $primary-color;
  }

  .button-row {
    .btn-submit {
      background: $primary-color;
      border: 2px solid $primary-color;
    }
  }

  .form-file {
    input {
      &::-webkit-file-upload-button {
        background-color: $primary-color;
        border: 2px solid $primary-color;
      }
    }
  }

}

.form-header {
  background-color: $primary-color;

  .logo {
    @if variable-exists(base64-logo) {
      background-image: url($base64-logo);
    } @else {
      background-image: url($logo);
    }
    @include scale-by-height($logo-dimensions, 50);
  }
}

.thank-you {
  .message {
    border: 1px solid $primary-color;
  }
}

.form-login-button {
  background-color: $primary-color;

  .logo {
    @if variable-exists(base64-logo) {
      background-image: url($base64-logo);
    } @else {
      background-image: url($logo);
    }
    @include scale-by-height($logo-dimensions, 50);
  }

  .button {
    color: $primary-color;
  }
}

@include breakpoint-specific('device-desktop') {
  .jotform-form {
    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0;

    .button-row .btn-submit:hover {
      border: 2px solid $primary-color;
      color: $primary-color;
    }
  }

  .form-login-button {
    .button {
      &:hover {
        background: $primary-color;
      }
    }
  }
}

.comment-prompt {
  &:hover {
    &:before,
    &:after {
      background: $primary-color;
    }
  }

  .comment-icon {
    &:before {
      @if variable-exists(icon) {
        background-image: url($icon);
      }
    }
  }

  .comment-btn {
    background: $primary-color;
    border: 2px solid $primary-color;
  }
}

@include breakpoint-specific('device-desktop') {
  .comment-prompt {
    .comment-btn:hover {
      border-color: $primary-color;
      color: $primary-color;
    }
  }
}

// premium

#wrapper {
  @if variable-exists(premium-bkgnd) {
    background: transparent url($premium-bkgnd) no-repeat 50% 0;
  }

  .footer {
    .button {
      color: $primary-color;
    }
  }
}

.content {
  @if variable-exists(premium-logo) {
    background: transparent url($premium-logo) no-repeat 50% 20px;
  }
}

@include breakpoint-specific('device-desktop') {
  #wrapper .footer .button:hover {
    background-color: $primary-color;
  }
}

.blokada-auto,
.blokada {

  #wrapper-premium {
    @if variable-exists(premium-bkgnd) {
      background-image: url($premium-bkgnd);
      display: block;
    } @else {
      display: none;
    }

    .content {
      @if variable-exists(premium-logo) {
        background-image: url($premium-logo);
      }
    }
  }
}

.msg-prompt {
  .logo {
    @if variable-exists(premium-logo) {
      background-image: url($premium-logo);
    }
  }
}

.subscribe {
  color: $primary-color;
}

@include breakpoint-specific('device-desktop') {
  .subscribe:hover {
    background-color: $primary-color;
  }
}

// profile

.profile-form {

  button {
    @include open-sans-bold(16px);
    background: $primary-color;
    border: 2px solid $primary-color;

    &.submitting {
      color: $primary-color;
    }
  }

  .arrow {
    &:before {
      color: $primary-color;
    }
  }
}

@include breakpoint-specific('device-desktop') {
  .profile-form {

    button {
      &:hover {
        color: $primary-color;
      }
    }
  }
}
