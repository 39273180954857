// sass-lint:disable indentation

@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('/@trinitymirrordigital/chameleon-branding/webfonts/woff2/icomoon.woff2') format('woff2'),
  url('/@trinitymirrordigital/chameleon-branding/webfonts/woff/icomoon.woff') format('woff');
}

@font-face {
  font-family: 'redacted_scriptbold';
  font-style: normal;
  font-weight: normal;
  src: url('/@trinitymirrordigital/chameleon-branding/webfonts/woff2/redacted-script-bold.woff2') format('woff2'),
  url('/@trinitymirrordigital/chameleon-branding/webfonts/woff/redacted-script-bold.woff') format('woff');
}
