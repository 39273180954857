// sass-lint:disable no-important
$important: !important;
// sass-lint:enable no-important

@import 'brand';

@import '../../../../../src/webfonts/scss/open-sans-condensed';
@import '../../../../../src/webfonts/scss/signika-negative-bold';
@import '../../../../../src/webfonts/scss/others';
@import '../../../../../src/webfonts/scss/sys-fallback';
@import '../../../../../src/webfonts/scss/futura-pt-condensed';

@import 'brand-sso';

// publication-specific configuration
@import 'config';

// sass-lint:disable no-important
@mixin publication-font() {
  @if $title-font == 'open-sans-condensed-bold' {
    font-family: 'Open Sans Condensed', sans-serif $important;
    font-weight: 700;
  } @else if $title-font == 'futura-pt-condensed' {
    font-family: 'futura-pt-condensed', sans-serif $important;
    font-weight: 800;
  } @else if ($title-font != 'open-sans-condensed-bold' and $title-font != 'futura-pt-condensed') {
    font-family: 'Signika Negative', 'Signika Negative-fallback', 'Open Sans', 'Open Sans-fallback', system-ui, sans-serif $important;
    font-weight: 700;
  }
}

// sass-lint:enable no-important
// .signika-loaded {
//   font-family: 'Signika Negative Bold', system-ui, sans-serif $important;
//   font-weight: 400;
// }

.publication-font {
  @include publication-font();
}

.placeholder-logo,
.use-image-placeholders figure {
  background: $aspen-silver;

  @if variable-exists(base64-logo) {
    &:before {
      background: url($base64-logo) no-repeat center;
      background-size: 50%;
      bottom: 0;
      content: '';
      display: block;
      filter: grayscale(100%);
      left: 0;
      opacity: .7;
      position: absolute;
      right: 0;
      top: 0;
    }
  } @else {
    &:before {
      background: url($logo) no-repeat center;
      background-size: 50%;
      bottom: 0;
      content: '';
      display: block;
      filter: grayscale(100%);
      left: 0;
      opacity: .7;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

// sass-lint:disable no-important, class-name-format
//opta widgets

.opta-score {
  .Opta-Team-Score {
    background-color: $primary-color $important;
  }
}

.opta-cricket {
  .Opta-MatchHeader {
    border-top: 4px solid $primary-color;
  }

  .Opta-CricketInningsBreakdown .Opta-Team {
    background-color: $primary-color $important;
  }

  .Opta-scorecard .Opta-On a {
    background-color: $primary-color $important;
  }

  .Opta-batting,
  .Opta-bowling,
  .Opta-fall-of-wickets {
    thead th {
      border-top: 4px solid $primary-color $important;
    }
  }

  .Opta-Ranking-Bars div {
    background-color: $primary-color $important;
    border-color: $primary-color $important;
  }
}

.live-event-entry[data-type='key-event'] {
  border-bottom: 6px solid $primary-color;
  border-left: 1px solid rgba($primary-color, .15);
  border-right: 1px solid rgba($primary-color, .15);

  .entry-info {
    background: $primary-color;
  }

  .entry-content {
    @include background-opacity($primary-color, .03);
  }
}

@each $section, $color in $sections {

  .channel-#{$section},
  .publication-theme .channel-#{$section} {
    // sass-lint:disable class-name-format

    .opta-score {
      .Opta-Team-Score {
        background-color: $color $important;
      }
    }

    .opta-cricket {
      .Opta-MatchHeader {
        border-top: 4px solid $color;
      }

      .Opta-CricketInningsBreakdown .Opta-Team {
        background-color: $color $important;
      }

      .Opta-scorecard .Opta-On a {
        background-color: $color $important;
      }

      .Opta-batting,
      .Opta-bowling,
      .Opta-fall-of-wickets {
        thead th {
          border-top: 4px solid $color $important;
        }
      }

      .Opta-Ranking-Bars div {
        background-color: $color $important;
        border-color: $color $important;
      }
    }

    // sass-lint:enable class-name-format

    .live-event-entry[data-type='key-event'] {
      border-bottom: 6px solid $color;
      border-left: 1px solid rgba($color, .15);
      border-right: 1px solid rgba($color, .15);

      .entry-info {
        background: $color;
      }

      .entry-content {
        @include background-opacity($color, .03);
      }
    }

    .video-icon:before {
      border-left-color: $color $important;
    }
  }
}

// specific overrides where we don't (yet) have full control over the markup

.static-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include publication-font();
  }

  a {
    color: $primary-color;
  }

  h3 {
    font-weight: 600;
  }
}

// video branding overrides
.mod-video {
  .suggested-video-failed .suggested-text {
    color: $primary-color;
  }

  .vjs-control-bar:not(.vjs-ad-control-bar) {
    .vjs-progress-control .vjs-play-progress,
    .vjs-volume-level {
      background-color: $primary-color;
    }
  }
}

// this seems a bit OTT - having to override a similar selector in article-service. Need to see if we can simplify it there
.article-body {
  p,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  i,
  strong {
    a {
      color: $primary-color;
    }
  }

  > a {
    color: $primary-color;
  }

  h3 {
    @include publication-font();
  }
}

.chart-container .number-statistic.default {
  background-color: $primary-color;
}

// scss-lint:disable SelectorDepth
[data-mod='mostRead'] [data-value='mostRead'] .teaser:before,
.base-layout [data-mod='mostRead'] [data-value='mostRead'] .teaser-highlight .inner:before {
  @include publication-font();
}
// scss-lint:enable SelectorDepth

.notifications {
  .notification-wrapper {
    @include breakpoint-specific('device-tablet-landscape-upwards') {
      @if variable-exists(icon) {
        background: $primary-color url($icon) no-repeat;
        background-size: 80px 80px;
      }
    }
  }
}

// conversation starter
.conversation-starter-container {
  .vf-conversation-starter_content { // sass-lint:disable-line class-name-format
    background-color: $hint !important;

    .vf-conversation-starter_text { // sass-lint:disable-line class-name-format
      color: $highlight !important;
    }

  }
}
