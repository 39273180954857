// Load in functions, mixins and variables
@import 'node_modules/@trinitymirrordigital/chameleon-core/src/scss/functions';
@import 'node_modules/@trinitymirrordigital/chameleon-core/src/scss/variables';
@import 'node_modules/@trinitymirrordigital/chameleon-core/src/scss/mixins';

$header-additional: $pitch-black !default;
$logo-dimensions: 300, 100 !default;
$scale-ratio: nth($logo-dimensions, 1)/nth($logo-dimensions, 2);

$publication: 'default' !default;
$publication-folder: '/@trinitymirrordigital/chameleon-branding/publications/#{$publication}';
$icon: '#{$publication-folder}/img/icon-#{$publication}.svg' !default;
$logo-name: 'logo-#{$publication}' !default;
$logo-name-earthquake: 'logo-#{$publication}-earthquake' !default;
$reversed-logo-name: 'reversed-logo-#{$publication}' !default;
$logo-type: if($publication == 'dailystar', 'svg', 'png');
$logo: '#{$publication-folder}/img/#{$logo-name}.#{$logo-type}' !default;
$logo-earthquake: '#{$publication-folder}/img/#{$logo-name-earthquake}.png' !default;
$reversed-logo: '#{$publication-folder}/img/#{$reversed-logo-name}.png' !default;
$html-loading-logo: '#{$publication-folder}/img/html-loader-logo.svg' !default;

$hint: rgba($primary-color, .12) !default;
$lowlight: rgba($primary-color, .9) !default;
$highlight: $primary-color !default;
$brand-color: $primary-color !default;
$brand-passion-color: lighten($brand-color, 5%) !default;

#logo {
  @if variable-exists(base64-logo) {
    background: $primary-color url($base64-logo) no-repeat left top;
  } @else {
    background: $primary-color url($logo) no-repeat left top;
  }
  @include scale-by-height($logo-dimensions, 50);
  background-size: 100% 100%;
}


.earthquake #logo {
  background: url($logo-earthquake) no-repeat left top;
  background-size: 100% 100%;
}
