// sass-lint:disable indentation

@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Condensed Bold'),
  url('/@trinitymirrordigital/chameleon-branding/webfonts/woff2/opensans-condbold-webfont.woff2') format('woff2'),
  url('/@trinitymirrordigital/chameleon-branding/webfonts/woff/opensans-condbold-webfont.woff') format('woff');
}
