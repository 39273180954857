// sass-lint:disable no-duplicate-properties
:root {
  #{--pub-logo}: url('#{$publication-folder}/img/#{$logo-name}.png');
  #{--pub-logo-earthquake}: url('#{$publication-folder}/img/#{$logo-name}-earthquake.png');
  #{--pub-primary-color}: $primary-color;
  #{--pub-earthquake-color}: #141414;
  #{--pub-secondary-color}: $hint; //hint color
  #{--pub-highlighted-color}: $highlight;
  #{--pub-lowlight-color}: $lowlight;
  #{--pub-regwall-logo}: url('#{$publication-folder}/img/html-loader-logo.svg');
  #{--pub-regwall-edging}: linear-gradient(#FFFFFF, #FFFFFF);
}
//sass-lint:enable
