// branding configuration
$publication: 'accrington';
$logo-dimensions: 600, 160;
$primary-color: #007B36;
$header-additional: #575756;
$brand-hue: 148;
$brand-saturation: 100%;

$brand-color: hsl($brand-hue, $brand-saturation, 24%);
$highlight: hsl($brand-hue, $brand-saturation, 44%);
$hint: hsl($brand-hue, $brand-saturation,  94%);
$lowlight: hsl($brand-hue, $brand-saturation,  9%);

// overrides
@mixin overrides() {
  .social-sites {
    @include social-icons(true);

    .icon {
      @include icons-background($white, $primary-color);
    }

  }
}
